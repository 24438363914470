import React, { useState } from "react";
import { Form, Modal, Input, Tooltip, Button, Slider } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import "../Borrow/BorrowModal.css";
import { useWallet } from "@suiet/wallet-kit";
import { RepayCoinWithAccountCap } from "./Repay";
import { apiToken, BaseUrl, getIndexAssetData } from "../../Common/Apis/Apis";
import axios from "axios";
import { pool } from "../../Common/Address/Address";
import { fetchTransformedAssetInfo } from '../../Common/Apis/naviIndexAssets';

const RepayModal = ({
  tokenLogo,
  tokenName,
  balance,
  CoinObjectId,
  balances,
  handleReload,
  repayReload,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [health, setHealth] = useState("-");
  const [assetsInfo, setAssetsInfo] = useState({});

  const wallet = useWallet();

  const handleClick = async () => {
    if (!wallet) {
      alert("Please connect your Sui wallet first.");
      return;
    }
    try {
      const result = await RepayCoinWithAccountCap(wallet, amount, tokenName);
      setIsModalOpen(false);
      handleReload();
      repayReload();
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  var newHealthFactorId = "new_health_factor_repay_" + tokenName;
  const showModal = () => {
    setIsModalOpen(true);
    setAmount("");
    fetchHealth();
    fetchStakesByIds();
    fetchTokenInfo();
    var newHF = document.getElementById(newHealthFactorId);
    if (newHF != null) {
      newHF.textContent = "";
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //const handleSliderChange = (value) => {
  //setSliderValue(value);
  //const newAmount = (value / 100) * balance;
  //setAmount(newAmount?.toFixed(2));
  //};

  const handleMaxClick = () => {
    setAmount(balance);
    updateHealthFactor(balance);
    //setSliderValue(100);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (isNaN(value)) {
      value = 0;
    }
    setAmount(value);
    updateHealthFactor(value);
    //setSliderValue((value / balance) * 100);
  };

  const mode = process.env.REACT_APP_MODE;
  const naviAddAccountCap =
    mode === "DEV"
      ? process.env.REACT_APP_NAVI_ACC_DEV
      : process.env.REACT_APP_NAVI_ACC_PROD;

  const fetchHealth = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}admin/getHealthFactor?key=${naviAddAccountCap}`,
        {
          headers: {
            Authorization: adminTokenToken.replace(/^"|"$/g, ""),
          },
        }
      );
      setHealth(response?.data?.data?.health.toFixed(2) || "0.00");
    } catch (err) {
      console.error(err.message);
      setHealth("0.00");
    }
  };

  const fetchTokenInfo = async () => {
    try {
      const transformedData = await fetchTransformedAssetInfo();
      setAssetsInfo(transformedData);
    } catch (error) {
      console.error("Error fetching token info:", error);
    }
  };

  const assetData = Object.values(assetsInfo)
    .filter((info) => ["wUSDC", "SUI", "WETH", "USDC"].includes(info.symbol))
    .map((info) => ({
      price: info.price,
      name: info.symbol,
    }));

  const [stakes, setStakes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const adminTokenToken = sessionStorage?.getItem("token");

  const fetchStakesByIds = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        BaseUrl + `admin/getNaviPortfolio?key=${naviAddAccountCap}`,
        {
          headers: {
            Authorization: adminTokenToken.replace(/^"|"$/g, ""),
          },
        }
      );
      setStakes(response?.data?.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const suiUsdDebt = stakes?.SUI?.borrowBalance * assetData[0]?.price;
  const usdcUsdDebt = stakes?.USDC?.borrowBalance * assetData[1]?.price;
  const wethUsdDebt = stakes?.WETH?.borrowBalance * assetData[2]?.price;

  const suiUsdSupply = stakes?.SUI?.supplyBalance * assetData[0]?.price;
  const usdcUsdSupply = stakes?.USDC?.supplyBalance * assetData[1]?.price;
  const wethUsdSupply = stakes?.WETH?.supplyBalance * assetData[2]?.price;

  const suiLiqThreshold = 0.8;
  const usdcLiqThreshold = 0.85;
  const wethLiqThreshold = 0.8;

  const updateHealthFactor = (value) => {
    var newHF = 0;
    if (tokenName == "SUI") {
      const newSuiUsdDebt = suiUsdDebt - value * assetData[0]?.price;
      const newTotalUsdDebt = newSuiUsdDebt + usdcUsdDebt + wethUsdDebt;
      newHF =
        (suiUsdSupply * suiLiqThreshold +
          usdcUsdSupply * usdcLiqThreshold +
          wethUsdSupply * wethLiqThreshold) /
        newTotalUsdDebt;
    }
    if (tokenName == "USDC") {
      const newUsdcUsdDebt = usdcUsdDebt - value * assetData[1]?.price;
      const newTotalUsdDebt = suiUsdDebt + newUsdcUsdDebt + wethUsdDebt;
      newHF =
        (suiUsdSupply * suiLiqThreshold +
          usdcUsdSupply * usdcLiqThreshold +
          wethUsdSupply * wethLiqThreshold) /
        newTotalUsdDebt;
    }
    if (tokenName == "WETH") {
      const newWethUsdDebt = wethUsdDebt - value * assetData[2]?.price;
      const newTotalUsdDebt = suiUsdDebt + usdcUsdDebt + newWethUsdDebt;
      newHF =
        (suiUsdSupply * suiLiqThreshold +
          usdcUsdSupply * usdcLiqThreshold +
          wethUsdSupply * wethLiqThreshold) /
        newTotalUsdDebt;
    }
    var healthElement = document.getElementById(
      "new_health_factor_repay_" + tokenName
    );
    if (parseFloat(newHF) <= 100) {
      healthElement.textContent = parseFloat(newHF).toFixed(2);
    } else {
      healthElement.textContent = "Infinity";
    }
  };

  const marks = {
    0: "0",
    25: "25",
    50: "50",
    75: "75",
    100: "100",
  };

  return (
    <>
      <span className="me-2" onClick={showModal}>
        Repay
      </span>
      <Modal
        title={`Repay ${tokenName}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="Total amount of assets to repay">
              <div className="d-flex align-items-center">
                Amount <MdOutlineInfo className="ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-between">
                <span></span>
                <span>Debt Balance: {balance}</span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  className="amount-value"
                  type="number"
                  step="any"
                  min={0}
                  max={balance}
                  value={amount} // Bind input value to state
                  onChange={handleInputChange} // Update state on input change
                />
                <button className="max-btn" onClick={handleMaxClick}>
                  MAX
                </button>
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={tokenLogo}
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>{tokenName}</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          <Form.Item>
            <span>Transaction Overview</span>
            <div className="transaction-overview">
              <div className="mb-2 d-flex justify-content-between">
                <span>Current Health Factor</span>
                <span>{health}</span>
              </div>
              <div className="mb-2 d-flex justify-content-between">
                <span>Repay Amount</span>
                <span>{amount <= 0 ? "" : amount}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>New Health Factor</span>
                <span id={newHealthFactorId}></span>
              </div>
            </div>
          </Form.Item>
          {/*<Slider
            marks={marks}
            value={sliderValue}
            onChange={handleSliderChange}
          />*/}
          <Button
            size="large"
            block
            className="supplyBtn"
            onClick={handleClick}
            disabled={amount <= 0 || amount > parseFloat(balance)}
          >
            {amount <= 0 ? "Enter Amount" : `Repay ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default RepayModal;
