import axios from 'axios';
import { pool } from '../Address/Address';
import { getIndexAssetData } from './Apis';

export async function fetchTransformedAssetInfo() {
  try {
    const response = await axios.get(getIndexAssetData);

    // Create a new object with the transformed data
    const transformedData = {};
    
    // Iterate through each asset in the response
    Object.keys(response.data).forEach(key => {
      const asset = response.data[key];
      
      // Check if this is the wrapped USDC coin type using the constant
      if (asset.coin_type === pool.USDC.type) {
        transformedData[key] = {
          ...asset,
          symbol: "wUSDC"
        };
      } else {
        transformedData[key] = asset;
      }
    });
    
    return transformedData;
  } catch (error) {
    console.error("Error fetching token info:", error);
    throw error;
  }
} 