import React, { useState } from "react";
import { Card, Button, Form, Spinner } from "react-bootstrap";
import { Input, Checkbox, Tooltip } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import Logo from "../../../Assets/Images/LogoDark.png";
import toast from "react-hot-toast";
import { BaseUrl } from "../../Common/Apis/Apis";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [passwordValid, setPasswordValid] = useState({
    length: false,
    uppercase: false,
    number: false,
    symbol: false,
    lowercase: false,
  });
  const navigate = useNavigate();

  const validatePassword = (password) => {
    setPasswordValid({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      symbol: /[@$!%*?&#]/.test(password),
      lowercase: /[a-z]/.test(password),
    });
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    validatePassword(value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { email } = e.target.elements;

    const payload = {
      email: email.value,
      password,
    };

    try {
      const response = await axios.post(
        `${BaseUrl}admin/login`,
        payload,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );


      if (response?.data?.data?.enable_auth) {
        setShowOtpInput(true);
        sessionStorage.setItem("token", response?.data?.data?.token);
        sessionStorage.setItem("enable_auth", response?.data?.data?.enable_auth);
        toast.success("OTP required.");
      } else {
        sessionStorage.setItem("token", response?.data?.data?.token);
        sessionStorage.setItem("enable_auth", response?.data?.data?.enable_auth);
        toast.success("Login Successful");
        navigate("/admin");
      }
    } catch (error) {      
      toast.error(error?.response?.data?.error || "Invalid email or password.");
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async () => {
    setLoading(true);
    try {
      const apiToken = sessionStorage?.getItem("token");
      if (!apiToken) {
        throw new Error("No token found");
      }

      const response = await axios.post(
        `${BaseUrl}admin/login_otp`,
        { otp },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': apiToken?.replace(/^"|"$/g, ""),
          },
        }
      );

      if (response?.data?.data?.token) {
        sessionStorage.setItem("token", response?.data?.data?.token);
        toast.success(response?.data?.message || "Login Successful");
        navigate("/admin");
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.error || "Invalid OTP. Please try again."
      );
      sessionStorage.removeItem("token");
    } finally {
      setLoading(false);
    }
  };

  const passwordCriteria = [
    { label: "At least 8 characters", valid: passwordValid.length },
    { label: "At least 1 uppercase letter", valid: passwordValid.uppercase },
    { label: "At least 1 lowercase letter", valid: passwordValid.lowercase },
    { label: "At least 1 number", valid: passwordValid.number },
    { label: "At least 1 symbol (@$!%*?&#)", valid: passwordValid.symbol },
  ];

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Card style={{ width: "40%", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)" }}>
        <Card.Body>
          <div className="d-flex justify-content-center">
            <img src={Logo} alt="Logo" className="mb-4 img-fluid" width={150} />
          </div>
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Input
                type="email"
                name="email"
                placeholder="Enter email"
                required
                disabled={showOtpInput} // Disable email input if OTP is being entered
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Tooltip
                title={
                  <div>
                    {passwordCriteria.map(({ label, valid }, index) => (
                      <p key={index} className="m-0">
                        {valid ? (
                          <CheckCircleOutlined className="text-success" />
                        ) : (
                          <CloseCircleOutlined className="text-danger" />
                        )}{" "}
                        {label}
                      </p>
                    ))}
                  </div>
                }
                placement="bottom"
              >
                <Input.Password
                  name="password"
                  placeholder="Enter password"
                  required
                  value={password}
                  onChange={handlePasswordChange}
                  disabled={showOtpInput} // Disable password input if OTP is being entered
                />
              </Tooltip>
            </Form.Group>

            {showOtpInput && (
              <Form.Group className="mb-3">
                <Form.Label>OTP</Form.Label>
                <br />
                <Input.OTP
                  variant="filled"
                  length={6}
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                />
              </Form.Group>
            )}
            <Link to={"/forgot_password"} style={{ textDecoration: "none", color:"#00264d"  }}>
              Forgot Password
            </Link>
            <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
              <Checkbox name="remember">Remember me</Checkbox>
              <Button
                type={showOtpInput ? "button" : "submit"}
                className="supplyBtn w-25"
                onClick={showOtpInput ? handleOtpSubmit : null}
                disabled={loading}
              >
                {loading ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : showOtpInput ? (
                  "Submit OTP"
                ) : (
                  "Login"
                )}
              </Button>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
