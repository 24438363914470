import React, { useState, useEffect } from "react";
import { Card, Alert, Divider, Row, Col, Tooltip, Menu, Dropdown } from "antd";
import USDC from "../../Assets/Svg/Usdc.svg";
import SUI from "../../Assets/Svg/Sui.svg";
import WETH from "../../Assets/Svg/Weth.svg";
import wUSDC from "../../Assets/Svg/wusdc.svg";
import "../Market/Market.css";
import { FaGift } from "react-icons/fa";
import { Badge, Table } from "react-bootstrap";
import SupplyModal from "../../Components/Modal/Supply/SupplyModal";
import BorrowModal from "../../Components/Modal/Borrow/BorrowModal";
import axios from "axios";
import { getConfig, pool } from "../../Components/Common/Address/Address";
import { useWallet } from "@suiet/wallet-kit";
import Supplied from "./Supplied";
import Borrow from "./Borrow";
import DepositModal from "../../Components/Modal/Deposit/DepositModal";
import WithdrawTreasuryModal from "../../Components/Modal/WithdrawTreasury/WithdrawTreasuryModal";
import { BsThreeDots } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import "./Navi.css";
import Swal from "sweetalert2";
import {
  BaseUrl,
  getIndexAssetData,
  RpcUrlSui_getObject,
} from "../../Components/Common/Apis/Apis";
import WithdrawModalFee from "../../Components/Modal/Withdrawfee/WithdrawFeeModal";
import { fetchTransformedAssetInfo } from '../../Components/Common/Apis/naviIndexAssets';

const fetchBalance = async (address, coinType) => {
  try {
    const response = await axios.post(RpcUrlSui_getObject, {
      jsonrpc: "2.0",
      id: 1,
      method: "suix_getCoins",
      params: [address, coinType, null, 50],
    });
    const data = response?.data?.result?.data || [];
    const totalBalance = data.reduce(
      (acc, obj) => acc + parseFloat(obj.balance || "0"),
      0
    );
    return {
      balance: totalBalance?.toFixed(2),
      coinObjectId: data || "",
    };
  } catch (error) {
    console.error("Error fetching balance:", error);
    return {
      balance: "0.00",
      coinObjectId: "",
    };
  }
};

const Navi = ({
  setNetWorth,
  SetAssetsUsdValue,
  SetSupplyedUsd,
  SetBorrowUsd,
  SetUsdToken,
}) => {
  const SUITokenAddress = pool.Sui?.type;
  const UsdcTokenAddress = pool.USDC?.type;
  const NUsdcTokenAddress = pool.NUSDC?.type;
  const WEthTokenAddress = pool.WETH?.type;
  const wallet = useWallet();

  const [assetsInfo, setAssetsInfo] = useState({});
  const [walletAddress, setWalletAddress] = useState("");
  const [coinObjectId, setCoinObjectId] = useState("");
  const [config, setConfig] = useState(null);
  const [reload, setReload] = useState(() => () => {});
  const [borrowreload, setBorrowReload] = useState(() => () => {});
  const [borrowAmt, SetBorrowAmt] = useState("0.00");
  const [balances, setBalances] = useState({
    SUI: "0.00",
    wUSDC: "0.00",
    USDC: "0.00",
    WETH: "0.00",
  });

  const [treasureSuiBalances, setTreasureSUIBalances] = useState({
    SUI: "0.00",
    USDC: "0.00",
    WETH: "0.00",
  });
  const [treasureUsdcBalances, setTreasureUsdcBalances] = useState();
  const [treasureUsdcFee, setTreasureUsdcFee] = useState("0.0");
  const [treasureUsdcNative, setTreasureUsdcNative] = useState("0.0");
  const [treasureUsdcNativeFee, setTreasureUsdcNativeFee] = useState("0.0");
  const [treasureWethBalances, setTreasureWethBalances] = useState();
  const [isRotating, setIsRotating] = useState(false);
  const adminTokenToken = sessionStorage?.getItem("token");
  const fetchTreasureBalance = async () => {
    try {
      const response = await axios.post(RpcUrlSui_getObject, {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          config?.suiTreasury,
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      setTreasureSUIBalances(
        response?.data?.result?.data?.content?.fields?.balance
      );
      return response?.data?.result?.data?.content?.fields?.balance || "0.00";
    } catch (error) {
      console.error("Error fetching balance:", error);
      return "0.00";
    }
  };
  const fetchTreasureUsdcBalance = async () => {
    try {
      const response = await axios.post(RpcUrlSui_getObject, {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          config.usdcTreasury,
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      setTreasureUsdcBalances(
        response?.data?.result?.data?.content?.fields?.balance
      );
      setTreasureUsdcFee(
        response?.data?.result?.data?.content?.fields?.fee_balance
      );
      return response?.data?.result?.data?.content?.fields?.balance || "0.00";
    } catch (error) {
      console.error("Error fetching balance:", error);
      return "0.00";
    }
  };
  const fetchTreasureUsdcNative = async () => {
    try {
      const response = await axios.post(RpcUrlSui_getObject, {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          config.usdcNativeTreasury,
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      setTreasureUsdcNative(
        response?.data?.result?.data?.content?.fields?.balance
      );
      setTreasureUsdcNativeFee(
        response?.data?.result?.data?.content?.fields?.fee_balance
      );
      return response?.data?.result?.data?.content?.fields?.balance || "0.00";
    } catch (error) {
      console.error("Error fetching balance:", error);
      return "0.00";
    }
  };

  const fetchTreasureWethBalance = async () => {
    try {
      const response = await axios.post(RpcUrlSui_getObject, {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          config.wethTreasury,
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      setTreasureWethBalances(
        response?.data?.result?.data?.content?.fields?.balance
      );
      return response?.data?.result?.data?.content?.fields?.balance || "0.00";
    } catch (error) {
      console.error("Error fetching balance:", error);
      return "0.00";
    }
  };

  const formatWithCommas = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    if (wallet.connected && wallet.account?.address) {
      setWalletAddress(sessionStorage?.getItem("walletAddress"));
    }
  }, [wallet?.connected, wallet?.account?.address]);

  const fetchBalances = async () => {
    if (wallet.connected && wallet.account?.address) {
      const walletAddress = wallet.account.address;
      const coinTypes = {
        SUI: SUITokenAddress,
        USDC: UsdcTokenAddress,
        WETH: WEthTokenAddress,
        NUSDC: NUsdcTokenAddress,
      };
      const suiBal = await fetchBalance(walletAddress, coinTypes.SUI);
      const wusdcBal = await fetchBalance(walletAddress, coinTypes.USDC);
      const usdcBal = await fetchBalance(walletAddress, coinTypes.NUSDC);
      const wethBal = await fetchBalance(walletAddress, coinTypes.WETH);
      setBalances({
        SUI: suiBal?.balance || "0.00",
        wUSDC: wusdcBal?.balance || "0.00",
        USDC: usdcBal?.balance || "0.00",
        WETH: wethBal?.balance || "0.00",
      });
      setCoinObjectId({
        SuiCoinObjectId: suiBal?.coinObjectId || "",
        UsdcCoinObjectId: wusdcBal?.coinObjectId || "",
        NUsdcCoinObjectId: usdcBal?.coinObjectId || "",
        WethCoinObjectId: wethBal?.coinObjectId || "",
      });
    } else {
      setBalances({
        SUI: "0.00",
        USDC: "0.00",
        NUSDC: "0.00",
        WETH: "0.00",
      });
    }
  };
  const fetchTokenInfo = async () => {
    try {
      const transformedData = await fetchTransformedAssetInfo();
      setAssetsInfo(transformedData);
      SetUsdToken(transformedData);
    } catch (error) {
      console.error("Error fetching token info:", error);
    }
  };
  useEffect(() => {
    fetchTreasureBalance();
    fetchTokenInfo();
    fetchBalances();
    fetchStakesByIds();
    handleReload();
  }, [walletAddress, wallet.connected, wallet.account?.address]);

  const formatBalance = (balance, decimals) => balance / 10 ** decimals;

  const assetIcons = {
    USDC,
    wUSDC,
    SUI,
    WETH,
  };

  const assets = Object.values(assetsInfo)
    .filter((info) => ["SUI", "WETH", "USDC", "wUSDC"].includes(info.symbol))
    .map((info) => ({
      asset: info.symbol,
      icon: assetIcons[info.symbol],
      info,
    }));

  const dataToSupply = assets.map(({ asset, icon, info }) => {
    const formattedBalance = formatBalance(
      balances[asset],
      asset === "SUI" ? 9 : asset === "WETH" ? 8 : asset === "USDC" ? 6 : 6
    );
    const Owner = process.env.REACT_APP_MODE;
    const OwnerWallet =
      Owner === "DEV"
        ? process.env.REACT_APP_DEV_ALLOWED_ADDRESS
        : process.env.REACT_APP_PROD_ALLOWED_ADDRESS;
    return {
      key: asset,
      asset,
      icon,
      balances: formattedBalance,
      Usdbalance: info?.price,
      apy: (parseFloat(info?.supply_rate) + parseFloat(info?.boosted)).toFixed(
        2
      ),
      valueApr: parseFloat(info?.supply_rate).toFixed(2),
      boosted: parseFloat(info?.boosted).toFixed(2),
      collateral: (info?.total_supply * info?.price).toFixed(2),
      borrowApy: parseFloat(info?.borrow_rate).toFixed(2),
      action: (
        <div>
          <Dropdown
            placement="bottom"
            trigger={["click"]}
            overlay={
              wallet?.account?.address === OwnerWallet ? (
                <Menu>
                  <Menu.Item key="supply">
                    <SupplyModal
                      fetchTreasureWethBalance={() =>
                        fetchTreasureWethBalance()
                      }
                      fetchTreasureUsdcBalance={() =>
                        fetchTreasureUsdcBalance()
                      }
                      fetchTreasureBalance={() => fetchTreasureBalance()}
                      treasureSuiBalances={treasureSuiBalances / 1e9}
                      treasureUsdcBalances={treasureUsdcBalances / 1e6}
                      treasureUsdcNative={treasureUsdcNative / 1e6}
                      treasureWethBalances={treasureWethBalances / 1e8}
                      tokenName={asset}
                      tokenApy={(
                        parseFloat(info?.supply_rate) +
                        parseFloat(info?.boosted)
                      )?.toFixed(2)}
                      usdValue={info?.price}
                      CoinObjectId={coinObjectId}
                      handleReload={() => handleReload()}
                      reload={reload}
                    />
                  </Menu.Item>
                  <Menu.Item key="deposit">
                    <DepositModal
                      fetchTreasureWethBalance={() =>
                        fetchTreasureWethBalance()
                      }
                      fetchTreasureUsdcBalance={() =>
                        fetchTreasureUsdcBalance()
                      }
                      fetchTreasureBalance={() => fetchTreasureBalance()}
                      tokenBalances={formattedBalance}
                      tokenName={asset}
                      tokenApy={
                        parseFloat(info?.supply_rate) +
                        parseFloat(info?.boosted)
                      }
                      usdValue={info?.price}
                      CoinObjectId={coinObjectId}
                      handleReload={() => handleReload()}
                      fetchBalances={() => fetchBalances()}
                    />
                  </Menu.Item>
                  <Menu.Item key="withdraw">
                    <WithdrawTreasuryModal
                      fetchTreasureWethBalance={() =>
                        fetchTreasureWethBalance()
                      }
                      fetchTreasureUsdcBalance={() =>
                        fetchTreasureUsdcBalance()
                      }
                      fetchTreasureBalance={() => fetchTreasureBalance()}
                      treasureSuiBalances={treasureSuiBalances / 1e9}
                      treasureUsdcBalances={treasureUsdcBalances / 1e6}
                      treasureUsdcNative={treasureUsdcNative / 1e6}
                      treasureWethBalances={treasureWethBalances / 1e8}
                      tokenName={asset}
                      tokenApy={(
                        parseFloat(info?.supply_rate) +
                        parseFloat(info?.boosted)
                      ).toFixed(2)}
                      usdValue={info?.price}
                      CoinObjectId={coinObjectId}
                      handleReload={() => handleReload()}
                      fetchBalances={() => fetchBalances()}
                    />
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu>
                  <Menu.Item>
                    <span
                      className="me-2"
                      onClick={() => {
                        if (wallet?.connected) {
                          Swal.fire({
                            title: "Incorrect Wallet Address",
                            text: "Please connect with the same origin wallet address.",
                            icon: "question",
                          });
                        } else {
                          Swal.fire({
                            title: "Connect Your Wallet",
                            text: "Please connect with the same origin wallet.",
                            icon: "error",
                          });
                        }
                      }}
                    >
                      Navi Supply
                    </span>
                  </Menu.Item>
                  <Menu.Item>
                    <span
                      className="me-2"
                      onClick={() => {
                        if (wallet?.connected) {
                          Swal.fire({
                            title: "Incorrect Wallet Address",
                            text: "Please connect with the same origin wallet address.",
                            icon: "question",
                          });
                        } else {
                          Swal.fire({
                            title: "Connect Your Wallet",
                            text: "Please connect with the same origin wallet.",
                            icon: "error",
                          });
                        }
                      }}
                    >
                      Treasury Deposit
                    </span>
                  </Menu.Item>
                  <Menu.Item>
                    <span
                      className="me-2"
                      onClick={() => {
                        if (wallet?.connected) {
                          Swal.fire({
                            title: "Incorrect Wallet Address",
                            text: "Please connect with the same origin wallet address.",
                            icon: "question",
                          });
                        } else {
                          Swal.fire({
                            title: "Connect Your Wallet",
                            text: "Please connect with the same origin wallet.",
                            icon: "error",
                          });
                        }
                      }}
                    >
                      Treasury Withdraw
                    </span>
                  </Menu.Item>
                </Menu>
              )
            }
          >
            <BsThreeDots />
          </Dropdown>
        </div>
      ),
    };
  });

  const netWorth = dataToSupply
    .slice(0, 3)
    .reduce((sum, item) => sum + (item?.Usdbalance * item?.balances || 0), 0)
    .toFixed(2);

  const treasureBalances = [
    treasureSuiBalances / 1e9,
    treasureUsdcNative / 1e6,
    treasureWethBalances / 1e8,
    treasureUsdcBalances / 1e6,
  ];

  const totalTreasuryUsd = dataToSupply.reduce((total, item, index) => {
    return total + item.Usdbalance * treasureBalances[index];
  }, 0);

  useEffect(() => {
    setNetWorth(netWorth);
    SetAssetsUsdValue(totalTreasuryUsd);
  }, [netWorth, setNetWorth, SetAssetsUsdValue]);

  const dataToBorrow = assets.map(({ asset, icon, info }) => ({
    key: asset,
    asset,
    icon,
    balance: (info?.total_borrow * info?.price).toFixed(2),
    borrowApy: parseFloat(info?.borrow_rate).toFixed(2),
    borrowReward: parseFloat(info?.borrow_reward_apy).toFixed(2),
    action: (
      <div>
        <Dropdown
          placement="bottom"
          trigger={["click"]}
          overlay={
            <Menu>
              <Menu.Item key="borrow">
                <BorrowModal
                  tokenLogo={icon}
                  tokenName={asset}
                  borrowAmt={borrowAmt}
                  usdValue={assetsInfo}
                  handleReload={() => handleReload()}
                  borrowreload={borrowreload}
                />
              </Menu.Item>
            </Menu>
          }
        >
          <BsThreeDots />
        </Dropdown>
      </div>
    ),
  }));

  const loadConfig = async () => {
    let detail = await getConfig();
    setConfig(detail);
  };
  useEffect(() => {
    loadConfig();
  }, []);

  const handleReload = () => {
    setIsRotating(true);
    fetchTreasureWethBalance();
    fetchTreasureUsdcBalance();
    fetchTreasureBalance();
    fetchBalances();
    fetchTreasureUsdcNative();
    setTimeout(() => {
      setIsRotating(false);
    }, 5000);
  };

  const [stakes, setStakes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const mode = process.env.REACT_APP_MODE;
  const naviAddAccountCap =
    mode === "DEV"
      ? process.env.REACT_APP_NAVI_ACC_DEV
      : process.env.REACT_APP_NAVI_ACC_PROD;
  const fetchStakesByIds = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        BaseUrl + `admin/getNaviPortfolio?key=${naviAddAccountCap}`,{
          headers: {
            Authorization: adminTokenToken.replace(/^"|"$/g, ""),
          },
        }
      );
      setStakes(response?.data?.data);
      SetBorrowUsd(response?.data?.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchStakesByIds();
    fetchTreasureUsdcNative();
  }, []);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Supplied
            SetSupplyedUsd={SetSupplyedUsd}
            CoinObjectId={coinObjectId}
            stakes={stakes}
            loading={loading}
            fetchStakes={() => fetchStakesByIds()}
            usdValue={assetsInfo}
            SetBorrowAmt={SetBorrowAmt}
            setReload={setReload}
            withdrawReload={() => handleReload()}
          />
        </Col>
        <Col xs={24} md={12}>
          <Borrow
            stakes={stakes}
            loading={loading}
            usdValue={assetsInfo}
            fetchStakes={() => fetchStakesByIds()}
            setBorrowReload={setBorrowReload}
            repayReload={() => handleReload()}
          />
        </Col>
        <Divider />
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Card
            title={
              <div className="d-flex justify-content-between align-items-center">
                Assets to Supply
                <h6>
                  <FiRefreshCcw
                    style={{ cursor: "pointer" }}
                    onClick={handleReload}
                    className={isRotating ? "rotateReload" : ""}
                  />
                </h6>
              </div>
            }
          >
            {netWorth <= 0 && (
              <Alert
                className="mb-4"
                message="Your SUI wallet is empty. Purchase or transfer assets to your SUI wallet."
                type="info"
                showIcon
              />
            )}
            <Table responsive>
              <thead>
                <tr>
                  <th>Asset</th>
                  <th>Treasury Balance</th>
                  <th>APY</th>
                  <th>Navi TVL</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dataToSupply.map((item) => (
                  <tr key={item.key}>
                    <td>
                      <img src={item.icon} alt="" />
                      <span>{item.asset}</span>
                    </td>
                    <Tooltip
                      title={`$ ${
                        item.asset === "SUI"
                          ? (
                              (treasureSuiBalances / 1e9) *
                              item.Usdbalance
                            )?.toFixed(2)
                          : item.asset === "WETH"
                          ? (
                              (treasureWethBalances / 1e8) *
                              item.Usdbalance
                            )?.toFixed(2)
                          : item.asset === "USDC"
                          ? (
                              (treasureUsdcBalances / 1e6) *
                              item.Usdbalance
                            )?.toFixed(2)
                          : (
                              (treasureUsdcBalances / 1e6) *
                              item.Usdbalance
                            )?.toFixed(2)
                      }`}
                    >
                      {wallet?.connected ? (
                        <td>
                          {item.asset === "SUI"
                            ? config?.suiTreasury
                              ? (treasureSuiBalances / 1e9 || 0.0)?.toFixed(4)
                              : "--"
                            : item.asset === "wUSDC"
                            ? config?.usdcTreasury
                              ? (treasureUsdcBalances / 1e6 || 0.0)?.toFixed(4)
                              : "--"
                            : item.asset === "USDC"
                            ? config?.usdcTreasury
                              ? (treasureUsdcNative / 1e6 || 0.0)?.toFixed(4)
                              : "--"
                            : config?.wethTreasury
                            ? (treasureWethBalances / 1e8 || 0.0)?.toFixed(5)
                            : "--"}
                        </td>
                      ) : (
                        <td>0.00</td>
                      )}
                    </Tooltip>
                    <Tooltip
                      title={
                        <div>
                          Vault APR: {item.valueApr}% <br />
                          Boosted APR: {item.boosted}%
                        </div>
                      }
                    >
                      <td>{item.apy}%</td>
                    </Tooltip>
                    <td>${formatWithCommas(item.collateral)}</td>
                    <td>{item.action}</td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <img src={wUSDC} alt="" className="me-2" />
                    wUSDC{" "}
                    <Badge pill bg="success">
                      Fee
                    </Badge>
                  </td>
                  <td>{(treasureUsdcFee / 1e6)?.toFixed(4)}</td>
                  <td></td>
                  <td></td>
                  <td>
                    <Dropdown
                      placement="bottom"
                      trigger={["click"]}
                      overlay={
                        <Menu>
                          <Menu.Item key="UsdcFeeWithdraw">
                            <WithdrawModalFee
                              feeBalance={treasureUsdcFee}
                              handleReload={() => handleReload()}
                              tokenName={"wUSDC"}
                            />
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <BsThreeDots />
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={USDC} alt="" className="me-2" />
                    USDC{" "}
                    <Badge pill bg="success">
                      Fee
                    </Badge>
                  </td>
                  <td>{(treasureUsdcNativeFee / 1e6)?.toFixed(4)}</td>
                  <td></td>
                  <td></td>
                  <td>
                    <Dropdown
                      placement="bottom"
                      trigger={["click"]}
                      overlay={
                        <Menu>
                          <Menu.Item key="UsdcFeeWithdraw">
                            <WithdrawModalFee
                              feeBalance={treasureUsdcNativeFee}
                              handleReload={() => handleReload()}
                              tokenName={"USDC"}
                            />
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <BsThreeDots />
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title="Assets to Borrow" className="h-100">
            <Table responsive>
              <thead>
                <tr>
                  <th>Asset</th>
                  <th>Navi Available</th>
                  <th>APY</th>
                  <th>Borrow Incentive</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dataToBorrow.map((item) => (
                  <tr key={item.key} className="">
                    <td>
                      <img src={item.icon} alt="" className="me-2" />
                      <span>{item.asset}</span>
                    </td>
                    <td>${formatWithCommas(item.balance)}</td>
                    <td>{item.borrowApy}%</td>
                    <td>
                      <FaGift style={{ color: "orange" }} />{" "}
                      <span>{item.borrowReward}</span>%
                    </td>
                    <td>{item.action}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Navi;
